import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Button from "../common/Button";
import device from "../common/device";
import parse from "html-react-parser";

const Wrapper = styled.section`
  padding-bottom: 104px;
  text-align: center;
  margin-top: -100px;
  position: relative;

  @media ${device.sm} {
    padding-bottom: 64px;
  }
`;
const Map = styled.div`
  background: black;
  height: 375px;
  border-radius: 10px;
  margin-bottom: 88px;
  background: url("${({ locationBanner }) => locationBanner}") no-repeat;
  background-size: cover;
  background-position: center;

  @media ${device.sm} {
    margin-bottom: 40px;
    height: 200px;
  }
`;
const Content = styled.div`
  max-width: 730px;
  margin-inline: auto;
  a {
    color: var(--ys-teal) !important;
  }
`;
const Title = styled.h2`
  font-size: 48px;
  line-height: 1;
  font-weight: 500;
  font-family: var(--font-secondary);
  color: var(--ys-teal);
  margin-bottom: 32px;
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  margin-bottom: 16px;
  color: var(--ys-black);

  &:first-of-type {
    font-weight: 800;
  }

  &:last-of-type {
    margin-bottom: 32px;
  }
`;

const CompanyDetail = ({ detail }) => {
  // console.log(detail?.locationBanner)
  return (
    <Wrapper>
      <Container>
        <Map locationBanner={detail?.locationBanner}></Map>
        <Content>
          <Title>{detail?.title}</Title>
          <Description>{detail?.subtitle}</Description>
          {detail?.content?.length > 0 &&
            detail?.content?.map((content) => (
              <Description>{parse(content || "")}</Description>
            ))}
          <Button href={"/contact-us"}>Get In Touch</Button>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default CompanyDetail;
