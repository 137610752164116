import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import device from "../fragments/common/device";
import PointerEvents from "../fragments/common/PointerEvents";
import HeroSlider from "../fragments/HeroSlider";
import Body from "../fragments/common/Body";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import ImagePreloader from "../fragments/common/ImagePreload";

const HomeContainer = styled(Container)`
  margin-top: 4rem;
  @media ${device.sm} {
    margin-top: 0rem;
  }
`;

const CardTitle = styled.p`
  text-transform: uppercase;
  font-family: Alternate, "sans-serif";
  font-size: 1.85rem !important;
  color: var(--ys-teal);
  padding-top: 1rem;
`;

const CardWrap = styled(Link)`
  span {
    color: var(--ys-teal);
    text-decoration: underline;
  }
`;

const ThumbImg = styled(ImagePreloader)`
  width: 100%;
  @media ${device.sm} {
    margin-top: -1rem;
  }
`;

const CardText = styled.p`
  min-height: 5rem;
  margin-bottom: 0;
  @media ${device.sm} {
    min-height: 3.5rem;
  }
`;

class Home extends Component {
  componentDidMount() {
    PointerEvents();
  }

  render() {
    const Card = (props) => {
      return (
        <Col style={{ marginBottom: "3rem" }} lg={3}>
          <CardWrap to={props.link}>
            <ThumbImg
              width={300}
              height={200}
              src={props.img}
              alt={props.title}
              loading="lazy"
            />
            <CardTitle>{props.title}</CardTitle>
            <CardText>{props.desc}</CardText>
            <Link to={props.link}>
              <span className="alternate">Read more</span>
            </Link>
          </CardWrap>
        </Col>
      );
    };

    return (
      <Body>
        <Helmet>
          <title>
            Yellow Signs - Signage & graphics specialist in Battersea, London
          </title>
          <meta
            name="description"
            content="London Signs for Business & Specialist in 3D Letters. We fabricate shop signs, illuminated 3D letters, window graphics and office privacy frosting"
          />
          <link rel="canonical" href={`${window.location.origin}/`} />
        </Helmet>

        <HeroSlider />
        <HomeContainer>
          <Row
            style={
              window.innerWidth < device.mid ? { marginTop: "2rem" } : null
            }
          >
            <Card
              imgTiny="/img/home/shop-signs-tiny.jpg"
              img="/img/home/shop-signs.webp"
              title="Shop & Retail Signs"
              desc="Shop signs can effectively drive footfall and are available in various materials and styles – from aluminium composite and stainless steel to acrylic and wood."
              link="shops-retail-signs"
            />

            <Card
              imgTiny="/img/home/3d-letters.webp"
              img="/img/home/3d-letters.webp"
              title="3D & Built-Up Letters"
              desc="Built-up acrylic letters, usually made from 5mm clear or coloured acrylic to allow light to travel through them and give an illuminated look."
              link="3d-builtup-letters"
            />

            <Card
              img="/img/home/plaques.webp"
              title="Wayfinding & Plaques"
              desc="Plaques and general wayfinding is the best way to encourage people to use a particular route or draw attention to areas or aspects of your business."
              link="plaques-wayfinding"
            />

            <Card
              img="/img/home/privacy-frosting.webp"
              title="Privacy Frosting"
              desc="Frosted window film is used to provide privacy in offices and industries such medical services. Frosted film can be cut and printed on to almost any shape or size."
              link="privacy-frosting"
            />

            <Card
              imgTiny="/img/home/vehicle-graphics-tiny.jpg"
              img="/img/home/vehicle-graphics.webp"
              title="Vehicle Graphics"
              desc="A perfect marketing tool and available in cut flat vinyl or full colour wraps, vehicle graphics can transform a car, van or lorry and increase brand awareness."
              link="vehicle-graphics"
            />

            <Card
              img="/img/home/projecting-signs.jpg"
              title="Projecting Signs"
              desc="Designed to project or protrude from a fascia, they allow potential customers to see the business from a distance. Available in rectangular, circular or bespoke shapes."
              link="projecting-signs"
            />

            <Card
              imgTiny="/img/home/neon-signs.jpg"
              img="/img/home/neon-signs.jpg"
              title="Neon Signs"
              desc="LED neon signs not only have the wow factor but are also more environmentally friendly, safer and more durable than traditional glass neon."
              link="neon-signs"
            />

            <Card
              img="/img/home/hoarding.webp"
              title="Hoarding"
              desc="Hoarding is a type of outdoor advertising that effectively promotes businesses in specific geographic areas, often surrounding building or construction sites."
              link="hoarding"
            />

            <Card
              img="/img/home/cut-vinyl.jpg"
              title="Cut Vinyl Graphics"
              desc="Cut vinyl is ideal for solid colour designs and suitable for smooth surfaces such as glass, metal, gloss painted wood, windows and vehicles."
              link="cut-vinyl-graphics"
            />

            <Card
              img="/img/home/flat-letters.jpg"
              title="Flat Cut Letters"
              desc="Flat letters can also be made by routering to shape from a variety of wood or metal and ideal for shop and retail signs, attached directly or with stand-off locators."
              link="flat-letters"
            />

            <Card
              img="/img/home/full-colour.jpg"
              title="Full Colour Graphics"
              desc="Full colour graphics are ideal for retail spaces or exhibitions and can be printed in almost any size using solvent and spot UV ink plus optional lamination for extra durability."
              link="full-colour-graphics"
            />

            <Card
              img="/img/home/digital-wallpaper.jpg"
              title="Digital Wallpaper"
              desc="Full colour printed wallpaper can bring any wall to life with any design you supply. Suitable for flat surfaces such as sealed painted walls and glass."
              link="digital-wallpaper"
            />

            <Card
              img="/img/home/trough-lighting.jpg"
              title="Trough Lights"
              desc="Trough lighting is usually used for shop or retail signs where there is no internal illumination. They often complete a classic look and can be powder coated to any colour."
              link="trough-lighting"
            />

            <Card
              img="/img/home/bespoke.webp"
              title="Bespoke Fabrication"
              desc="Our wide range of fabrication equipment, machinery and expert production team means we can offer solutions to almost any signage, graphic or print project."
              link="bespoke-fabrication"
            />

            <Card
              img="/img/home/print.jpg"
              title="Digital & Litho Print"
              desc="We also offer a wide range of digital and litho print, as well as embossing, debossing and foiling. Same-day print is our speciality, with our sister company Yellow Print."
              link="print"
            />

            <Card
              img="/img/home/trade.webp"
              title="Trade Signage"
              desc="As well as working with end users and businesses we also work with other signage and graphics companies to supply a trade solution with trade prices and super-fast turnaround."
              link="trade"
            />
          </Row>
        </HomeContainer>
      </Body>
    );
  }
}

export default withRouter(Home);
