import React, { Component } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import device from "./common/device";
import styled from "styled-components";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const FormWrapper = styled.form`
  margin: 4rem 0;
  @media ${device.sm} {
    margin: 2rem 0;
  }
`;

const Title = styled.h3`
  color: var(--ys-teal);
  margin-bottom: 0rem;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 0.85rem;
  padding-top: 1rem;
  color: #4b4b4b;
  line-height: 1.2;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  &:focus {
    outline: none;
  }
`;

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  &:focus {
    outline: none;
  }
`;

const UploadWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.8fr;
  margin-top: 1rem;
  @media ${device.md} {
    display: none;
  }
`;

const SuccessWrapper = styled.div`
  position: absolute;
  left: 50%;
  transition: 1s;
`;

const SuccessTxt = styled.div`
  position: relative;
  left: -50%;
  margin-top: 50%;
`;

const ValidationInfo = styled.span`
  color: red;
  margin-left: 0.5rem;
  font-size: 0.85rem;
`;

class QuoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ContactName: "",
      CompanyName: "No company name provided",
      Address: "No address provided",
      Tel: "",
      Email: "",
      Message: "No message provided",
      File: "",

      QuoteFormOpacity: 1,
      QuoteFormPointerEvents: "all",
      SuccessOpacity: 0,
      SuccessPointerEvents: "none",

      NameValidate: false,
      NameValidationTxt: "*",
      TelValidate: false,
      TelValidationTxt: "*",
      EmailValidate: false,
      EmailValidationTxt: "*",
      file: null,
      fileName: null,
      fileType: null,
      fileS3url: null,

      requestBtnTxt: "REQUEST A QUOTE OR SITE VISIT",
      requestBtnPointerEvents: "all",
      requestBtnOpacity: "1",
    };
  }

  onContactNameChange(e) {
    this.setState({ ContactName: e.target.value });
  }

  onCompanyNameChange(e) {
    this.setState({ CompanyName: e.target.value });
  }

  onAddressChange(e) {
    this.setState({ Address: e.target.value });
  }

  onTelephoneChange(e) {
    this.setState({ Tel: e.target.value });
  }

  onEmailChange(e) {
    this.setState({ Email: e.target.value });
  }

  onMessageChange(e) {
    this.setState({ Message: e.target.value });
  }

  onFileChange(e) {
    const [selectedFile] = e.target.files;
    if (selectedFile) {
      if (selectedFile.size > 15000000) {
        // console.log("Max file size is 15MB"); // Change this to real alert
      } else {
        this.setState({
          file: selectedFile,
          fileName: selectedFile.name,
          fileType: selectedFile.type,
        });
      }
    }
    // console.log(selectedFile)
    // console.log(selectedFile.name)
    // console.log(selectedFile.type)
  }

  async handleSubmit(e) {
    e.preventDefault();

    // Validation
    if (!this.state.Email.includes("@" && ".")) {
      this.setState({
        EmailValidationTxt: "* please enter a valid email address",
        EmailValidate: false,
      });
    } else {
      this.setState({
        EmailValidate: true,
        EmailValidationTxt: "*",
      });
    }

    if (this.state.ContactName === "") {
      this.setState({
        NameValidationTxt: "* please enter your name",
        NameValidate: false,
      });
    } else {
      this.setState({
        NameValidate: true,
        NameValidationTxt: "*",
      });
    }

    if (this.state.Tel === "") {
      this.setState({
        TelValidationTxt: "* please enter your telephone number",
        TelValidate: false,
      });
    } else {
      this.setState({
        TelValidate: true,
        TelValidationTxt: "*",
      });
    }

    setTimeout(() => {
      // Need to set timeout to let the state update
      if (
        this.state.EmailValidate &&
        this.state.NameValidate &&
        this.state.TelValidate
      ) {
        // If all validations have passed, execute function

        // API for sending contact form content to nodemailer (Managed in local express)
        const nodemailerAPI =
          "https://0p6owqyjoi.execute-api.us-east-1.amazonaws.com/prod/send-quote-request";
        // API to get presigned URL for S3 fileupload (Managed in AWS Lambda)
        const presignedUrlAPI =
          "https://i1z30yx8i3.execute-api.us-east-1.amazonaws.com/default/getPresignedURLforEmailAttachments";
        // S3 bucket url
        const s3bucketUrl =
          "https://ys-email-attachments-bucket.s3.eu-west-2.amazonaws.com/";

        // Informing user that the email sending has been initiated and disabling submit button
        this.setState({
          requestBtnTxt: "SENDING QUOTE REQUEST...",
          requestBtnPointerEvents: "none",
          requestBtnOpacity: ".5",
        });

        // If file added, add it to S3 and then send all data to nodemailer START
        if (this.state.file) {
          let dataValues = {
            fileName: this.state.fileName,
            fileType: this.state.fileType,
          };

          // console.log("START")
          // console.log(this.state.fileName)
          // console.log(this.state.fileType)
          // console.log("END")

          // Get presigned URL
          axios.post(presignedUrlAPI, dataValues).then((res) => {
            // console.log(res, 123123123123);
            // console.log(res.data.photoFilename);

            // Define the final file link
            this.setState({
              fileS3url: s3bucketUrl + res.data.photoFilename,
            });

            // Using the presigned URL upload image to S3
            const url = res.data.uploadURL;
            const axiosConfig = {
              headers: {
                "Content-Type": this.state.fileType,
                "Cache-Control": "max-age=31104000",
              },
            };

            axios
              .put(url, this.state.file, axiosConfig)
              .then(async (response) => {
                // console.log(response)

                // Once the image is successfully uploaded, put the attachment link in contact form with rest of the details
                let emailToMe = {
                  ContactName: this.state.ContactName,
                  CompanyName: this.state.CompanyName,
                  Address: this.state.Address,
                  Tel: this.state.Tel,
                  Email: this.state.Email,
                  Message: this.state.Message,
                  File: this.state.fileS3url,
                };

                await axios.post(
                  "https://submit-form.com/lXwroAmP2",
                  emailToMe
                );

                // Send everyting to nodemailer
                // axios.post(nodemailerAPI, emailToMe).then((res) => {
                //   console.log("All data is passed"); // to Nodemailer
                // });

                window.dataLayer.push({
                  event: "quote_form_submit",
                  eventCategory: "form_submit",
                  eventAction: "click",
                  eventLabel: "Quote Form Submit",
                });
                this.setState({
                  QuoteFormOpacity: 0.1,
                  QuoteFormPointerEvents: "none",
                  SuccessOpacity: 1,
                  SuccessPointerEvents: "all",
                  requestBtnTxt: "QUOTE REQUEST RECEIVED",
                });
              })
              .catch((err) => {
                console.log(err.response);
              });
          });
        }
        // If file added, add it to S3 and then send all data to nodemailer END
        // If no file added, send data to nodemailer straight away START
        if (!this.state.file) {
          // console.log(false)

          let emailToMe = {
            ContactName: this.state.ContactName,
            CompanyName: this.state.CompanyName,
            Address: this.state.Address,
            Tel: this.state.Tel,
            Email: this.state.Email,
            Message: this.state.Message,
          };

          // Send info to nodemailer
          axios.post("https://submit-form.com/lXwroAmP2", emailToMe);
          // axios.post(nodemailerAPI, emailToMe).then((res) => {
          //   console.log("Contact data is passed to Nodemailer");
          // });
          window.dataLayer.push({
            event: "quote_form_submit",
            eventCategory: "form_submit",
            eventAction: "click",
            eventLabel: "Quote Form Submit",
          });
          this.setState({
            QuoteFormOpacity: 0.1,
            QuoteFormPointerEvents: "none",
            SuccessOpacity: 1,
            SuccessPointerEvents: "all",
            requestBtnTxt: "QUOTE REQUEST RECEIVED",
          });
        }
        // If no file added, send data to nodemailer straight away END
      }
    }, 200);
  }

  render() {
    return (
      <FormWrapper onSubmit={this.handleSubmit.bind(this)}>
        <Container>
          <SuccessWrapper
            style={{
              opacity: this.state.SuccessOpacity,
              pointerEvents: this.state.SuccessPointerEvents,
            }}
          >
            <SuccessTxt>
              Thank you! Someone from the team will be in touch very soon.
            </SuccessTxt>
          </SuccessWrapper>
          <Row
            style={{
              opacity: this.state.QuoteFormOpacity,
              pointerEvents: this.state.QuoteFormPointerEvents,
              transition: "1s",
            }}
          >
            <Col lg={6} sm={12} style={{ marginBottom: "1rem" }}>
              <Title>TELL US ABOUT YOU…</Title>
              <Label htmlFor="Name" required>
                Contact Name
              </Label>
              <ValidationInfo>{this.state.NameValidationTxt}</ValidationInfo>
              <Input
                type="text"
                onChange={this.onContactNameChange.bind(this)}
              />
              <Label htmlFor="Company">Company Name</Label>
              <Input
                type="text"
                onChange={this.onCompanyNameChange.bind(this)}
              />
              <Label htmlFor="Address">
                Address (of installation, if applicable)
              </Label>
              <Input type="text" onChange={this.onAddressChange.bind(this)} />
              <Label htmlFor="Phone">Telephone Number</Label>
              <ValidationInfo>{this.state.TelValidationTxt}</ValidationInfo>
              <Input type="tel" onChange={this.onTelephoneChange.bind(this)} />
              <Label htmlFor="Email">Email Address</Label>
              <ValidationInfo>{this.state.EmailValidationTxt}</ValidationInfo>
              <Input type="text" onChange={this.onEmailChange.bind(this)} />
            </Col>
            <Col lg={6} sm={12}>
              <Title>TELL US ABOUT YOUR PROJECT…</Title>
              <Label htmlFor="Textarea" required>
                Include any preferences to the general look, feel, materials
                used or any other information which may help us quote.
              </Label>
              <Textarea
                rows={6}
                maxlength="2000"
                onChange={this.onMessageChange.bind(this)}
              />

              <UploadWrapper>
                <label htmlFor="fileUpload" id="fileUploadBtn">
                  ATTACH FILE
                </label>
                <input
                  type="file"
                  name="file"
                  multiple={false}
                  accept=".pdf, .jpg, .png"
                  id="fileUpload"
                  style={{
                    padding: "0",
                    opacity: "0",
                    position: "absolute",
                    zIndex: "-1",
                  }}
                  onChange={this.onFileChange.bind(this)}
                />

                <Label
                  htmlFor="Upload"
                  style={{
                    display: "inline",
                    letterSpacing: "-.21px",
                    padding: "0 0 0 1rem",
                  }}
                >
                  Include any preferences to the general look, feel, materials
                  used or any other information which may help us quote. Max
                  file size is 15MB.
                </Label>
              </UploadWrapper>
              {this.state?.fileName && <p>{this.state?.fileName}</p>}

              <button
                className="large"
                id="formsubmit"
                style={{
                  marginTop: "2rem",
                  pointerEvents: this.state.requestBtnPointerEvents,
                  opacity: this.state.requestBtnOpacity,
                }}
              >
                {this.state.requestBtnTxt}
              </button>
            </Col>
          </Row>
        </Container>
      </FormWrapper>
    );
  }
}

export default QuoteForm;
